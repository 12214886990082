// PlatformList.js
import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Pagination from '../common/Pagination'; // Import the reusable Pagination component
import ModalPopup from '../common/ModalPopup';
import RegisterForm from './RegisterForm';
import { EyeIcon } from '@heroicons/react/24/outline';
import { toast } from 'sonner';

const PlatformList = ({ onTitleChange }) => {
  const [platforms, setPlatforms] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(null); // Initialize as null to avoid unnecessary initial fetch
  const recordsPerPage = 6;
  const [totalPages, setTotalPages] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  // Extract the page number from URL on component load
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const page = queryParams.get('page') ? parseInt(queryParams.get('page')) : 1;
    setCurrentPage(page);
  }, [location.search]);

  // Fetch Platforms - useCallback to memoize the function
  const fetchPlatforms = useCallback(
    async (page) => {
      if (page === null) return; // Skip fetch if page is null

      setLoading(true);
      const controller = new AbortController();
      const signal = controller.signal;

      try {
        const response = await fetch(
          `${process.env.REACT_APP_CORE_API_BASE_URL}/api/platform-pagination?page=${page}&limit=${recordsPerPage}`,
          { signal }
        );
        const data = await response.json();

        if (data.success) {
          setPlatforms(data.data);

          /**
           * TODO
           * API Response Structure
           * Ensure that your API returns the necessary pagination metadata
            {
              "success": true,
              "data": [Array of platform objects],
              "totalPages": 5,
              "totalRecords": 10,
              "currentPage": 1
            }
           */

          setTotalPages(data.totalPages || Math.ceil(data.totalRecords / recordsPerPage));
        } else {
          setError('Failed to fetch platforms');
        }
      } catch (err) {
        if (err.name === 'AbortError') {
          console.log('Fetch aborted');
        } else {
          setError('Error fetching data');
        }
      } finally {
        setLoading(false);
      }
    },
    [recordsPerPage]
  );

  // Fetch data whenever the current page changes
  useEffect(() => {
    if (currentPage !== null) {
      fetchPlatforms(currentPage);
    }
  }, [currentPage, fetchPlatforms]);

  const handleDelete = async (id) => {
    const confirmDelete = window.confirm('Are you sure you want to delete this platform?');
    if (confirmDelete) {
      try {

        const token = localStorage.getItem("token");
        const requestPayload = { 'id': id };

        const headers = {
          'Content-Type': 'application/json'
        }

        if(token){
          headers['Authorization'] = `Bearer ${token}`
        }

        const response = await fetch(`${process.env.REACT_APP_CORE_API_BASE_URL}/api/platform`, {
          method: 'DELETE',
          headers: headers,
          body: JSON.stringify(requestPayload)
        });
        const data = await response.json();

        if (data.success) {
          toast.success(data.message);
          refreshPlatforms()
        } else {
          toast.error(data.message);
        }
      } catch (err) {
        toast.error('Error deleting platform.');
      }
    }
  };

  const userList = async (tag, name) => {
    onTitleChange(name);
    navigate(`users?tag=${tag}`)
  }

  const handleAddNew = () => {
    setIsModalOpen(true); // Open the modal
  };

  const handleModalClose = () => {
    setIsModalOpen(false); // Close the modal
  };

  const refreshPlatforms = () => {
    fetchPlatforms(currentPage);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <>
      <div className="container mx-auto">
        <div className="flex justify-end mb-4">
          <button
            onClick={handleAddNew}
            className="text-gray-950 hover:text-white border border-gray-700 hover:bg-gray-950 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 dark:border-gray-950 dark:text-gray-950 dark:hover:text-white dark:hover:bg-gray-950"
          >
            + Add New
          </button>
        </div>

        <div className="relative overflow-x-auto mt-7">
          <table className="w-full text-sm text-left text-gray-700 border-collapse">
            <thead className="text-white bg-blue-500">
              <tr>
                <th scope="col" className="px-6 py-3 border-b-2 border-gray-300">Platform Name</th>
                <th scope="col" className="px-6 py-3 border-b-2 border-gray-300">Tag</th>
                <th scope="col" className="px-6 py-3 border-b-2 border-gray-300 text-center">Actions</th>
              </tr>
            </thead>
            <tbody>
              {platforms.map((platform) => (
                <tr key={platform.id} className="bg-white border-b border-gray-300">
                  <td className="px-6 py-4 w-3/6 font-medium text-gray-900 whitespace-nowrap">{platform.name}</td>
                  <td className="px-6 py-4">{platform.tag}</td>
                  <td className="px-6 py-4 w-48 text-center w-full justify-center flex">
                  <EyeIcon onClick={() => userList(platform.tag, platform.name)} className="w-6 h-6 cursor-pointer align-middle mr-2 self-center" />
                    <button
                      onClick={() => handleDelete(platform.id)}
                      className="bg-red-500 text-white px-3 py-1 rounded-md hover:bg-red-700"
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          {/* Use Pagination Component */}
          <Pagination
            currentPage={currentPage || 1} // Default to page 1 if currentPage is null (unlikely at this point)
            totalPages={totalPages}
            onPageChange={(page) => {
              if (page !== currentPage) {
                navigate(`?page=${page}`);
              }
            }}
          />
        </div>
      </div>

      <ModalPopup popUpTitle={'Create Platform'} isOpen={isModalOpen} onClose={handleModalClose}>
        <RegisterForm onClose={handleModalClose} onRefresh={refreshPlatforms} />
      </ModalPopup>

    </>
  );
};

export default PlatformList;
