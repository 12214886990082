import axios from 'axios';

export const FETCH_PLATFORMS_REQUEST = 'FETCH_PLATFORMS_REQUEST';
export const FETCH_PLATFORMS_SUCCESS = 'FETCH_PLATFORMS_SUCCESS';
export const FETCH_PLATFORMS_FAILURE = 'FETCH_PLATFORMS_FAILURE';

const fetchPlatformsRequest = () => {
  return {
    type: FETCH_PLATFORMS_REQUEST,
  };
};

const fetchPlatformsSuccess = (data) => {
  return {
    type: FETCH_PLATFORMS_SUCCESS,
    payload: data,
  };
};

const fetchPlatformsFailure = (error) => {
  return {
    type: FETCH_PLATFORMS_FAILURE,
    payload: error,
  };
};

// Thunk function to fetch data
export const fetchPlatforms = () => {
  return (dispatch) => {
    dispatch(fetchPlatformsRequest());
    axios
      .get(`${process.env.REACT_APP_CORE_API_BASE_URL}/api/platform`)
      .then((response) => {
        dispatch(fetchPlatformsSuccess(response.data.data));
      })
      .catch((error) => {
        dispatch(fetchPlatformsFailure(error.message));
      });
  };
};
