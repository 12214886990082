import { combineReducers } from 'redux';
import platformReducer from './platform/platformReducer';
import authReducer from '../slices/authSlice'

const rootReducer = combineReducers({
  platform: platformReducer,
  auth: authReducer
});

export default rootReducer;
