// PlatformList.js
import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Pagination from '../common/Pagination'; // Import the reusable Pagination component

const PlatformUserList = () => {
  const [platformUsers, setPlatformUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(null); // Initialize as null to avoid unnecessary initial fetch
  const recordsPerPage = 6;
  const [totalPages, setTotalPages] = useState(1);
  const [tag, setPlatformTag] = useState('')

  const navigate = useNavigate();
  const location = useLocation();

  // Extract the page number from URL on component load
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const page = queryParams.get('page') ? parseInt(queryParams.get('page')) : 1;
    const platformTag = queryParams.get('tag')
    setCurrentPage(page);
    setPlatformTag(platformTag)
  }, [location.search]);

  // Fetch Platform users - useCallback to memoize the function
  const fetchPlatformUsers = useCallback(
    async (page) => {
      if (page === null) return; // Skip fetch if page is null

      setLoading(true);
      const controller = new AbortController();
      const signal = controller.signal;

      try {
        const response = await fetch(
          `${process.env.REACT_APP_CORE_API_BASE_URL}/api/user-track/${tag}?page=${page}&limit=${recordsPerPage}`,
          { signal }
        );
        const data = await response.json();

        if (data.success) {
          setPlatformUsers(data.data);

          /**
           * TODO
           * API Response Structure
           * Ensure that your API returns the necessary pagination metadata
            {
              "success": true,
              "data": [Array of platform objects],
              "totalPages": 5,
              "totalRecords": 10,
              "currentPage": 1
            }
           */

          setTotalPages(data.totalPages || Math.ceil(data.totalRecords / recordsPerPage));
        } else {
          setError('Failed to fetch platform users');
        }
      } catch (err) {
        if (err.name === 'AbortError') {
          console.log('Fetch aborted');
        } else {
          setError('Error fetching data');
        }
      } finally {
        setLoading(false);
      }
    },
    [recordsPerPage, tag]
  );

  // Fetch data whenever the current page changes
  useEffect(() => {
    if (currentPage !== null) {
      fetchPlatformUsers(currentPage);
    }
  }, [currentPage, fetchPlatformUsers]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <>
    {platformUsers.length !== 0 ?
    (
      <>
            <div className="container mx-auto">

              <div className="relative overflow-x-auto mt-7">
                <table className="w-full text-sm text-left text-gray-700 border-collapse">
                  <thead className="text-white bg-blue-500">
                    <tr>
                      <th scope="col" className="px-6 py-3 border-b-2 border-gray-300">Name</th>
                      <th scope="col" className="px-6 py-3 border-b-2 border-gray-300">Email</th>
                      <th scope="col" className="px-6 py-3 border-b-2 border-gray-300">MOBILE</th>
                      <th scope="col" className="px-6 py-3 border-b-2 border-gray-300">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {platformUsers.map((user) => (
                      <tr key={user.id} className="bg-white border-b border-gray-300">
                        <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">{user.first_name + user.last_name}</td>
                        <td className="px-6 py-4">{user.email}</td>
                        <td className="px-6 py-4">{user.mobile}</td>
                        <td className="px-6 py-4">{user.status}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>

                {/* Use Pagination Component */}
                <Pagination
                  currentPage={currentPage || 1} // Default to page 1 if currentPage is null (unlikely at this point)
                  totalPages={totalPages}
                  onPageChange={(page) => {
                    if (page !== currentPage) {
                      navigate(`?tag=${tag}&page=${page}`);
                    }
                  }}
                />
              </div>
            </div>
      </>
    ) :
    (<h1 className='py-8 text-xl'>No Data Found!</h1>)
    }

    </>
  );
};

export default PlatformUserList;
