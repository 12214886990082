// Pagination.js
import React from 'react';

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const getPaginationNumbers = () => {
    let pages = [];

    if (totalPages <= 10) {
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      if (currentPage <= 6) {
        pages = [1, 2, 3, 4, 5, 6, '...', totalPages - 1, totalPages];
      } else if (currentPage > 6 && currentPage <= totalPages - 5) {
        pages = [1, '...', currentPage - 2, currentPage - 1, currentPage, currentPage + 1, currentPage + 2, '...', totalPages];
      } else {
        pages = [1, '...', totalPages - 5, totalPages - 4, totalPages - 3, totalPages - 2, totalPages - 1, totalPages];
      }
    }

    return pages;
  };

  return (
    <div className="flex justify-center items-center mt-4 space-x-2">
      <button
        onClick={() => onPageChange(Math.max(currentPage - 1, 1))}
        disabled={currentPage === 1}
        className={`px-3 py-1 bg-blue-500 text-white rounded ${
          currentPage === 1 ? 'cursor-not-allowed opacity-50' : 'hover:bg-blue-700'
        }`}
      >
        &larr;
      </button>

      {getPaginationNumbers().map((page, index) => (
        <button
          key={index}
          onClick={() => typeof page === 'number' && onPageChange(page)}
          className={`px-3 py-1 rounded ${
            page === currentPage
              ? 'bg-blue-700 text-white'
              : typeof page === 'number'
              ? 'bg-gray-200 text-gray-700 hover:bg-blue-500 hover:text-white'
              : 'cursor-default text-gray-500'
          }`}
          disabled={page === '...'}
        >
          {page}
        </button>
      ))}

      <button
        onClick={() => onPageChange(Math.min(currentPage + 1, totalPages))}
        disabled={currentPage === totalPages}
        className={`px-3 py-1 bg-blue-500 text-white rounded ${
          currentPage === totalPages ? 'cursor-not-allowed opacity-50' : 'hover:bg-blue-700'
        }`}
      >
        &rarr;
      </button>
    </div>
  );
};

export default Pagination;
