
import React, {useState, useEffect} from 'react'
import TitleHeader from '../common/TitleHeader'
import ExtensionList from './ExtensionList';
import Select from 'react-select'
import { useSelector, useDispatch } from 'react-redux';
import { fetchPlatforms } from '../../actions/platform/platformActions';

function Extension() {
  const dispatch = useDispatch();
  const [selectedOption, setSelectedOption] = useState(null);
  const { platforms} = useSelector((state) => state.platform);
  useEffect(() => {
    dispatch(fetchPlatforms());
  }, [dispatch]);

  const platformOptions = platforms.map((platform) => ({
    value: platform.tag,
    label: platform.name,
  }));

  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    console.log('Selected Platform:', selectedOption?.value); // Log the selected value
  };


  return (
    <>
    <TitleHeader title={'Extensions'} description={'List of extensions'} />
    <Select className='w-1/6 mt-7' options={platformOptions} placeholder={'Select Platform'} onChange={handleChange}/>
    <ExtensionList tagName={selectedOption?.value} />
    
    </>
  )
}

export default Extension
