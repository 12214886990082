import './App.css';
import { createBrowserRouter, RouterProvider, Outlet } from 'react-router-dom';
import { DefaultSidebar } from './components/SideBar';
import Platform from './components/Platform/Platform';
import NavBar from './components/NavBar';
import { Toaster } from 'sonner';
import PlatformUserList from './components/Platform/PlatformUserList';
import UserCourse from './components/userCourse/UserCourse';
import Courses from './components/courses/Courses';
import AuthGuard from './components/AuthGuard';
import Extention from './components/extension/Extension'
import User from './components/users/User';

const router = createBrowserRouter(
  [
    {
      path: '/',
      element: (
        <AuthGuard>
          <div className="App">
            <NavBar />
            <div className="main-content">
              <DefaultSidebar />
              <div className="p-4 sm:ml-64">
                <Outlet />
              </div>
            </div>
            <Toaster richColors position="top-right" />
          </div>
        </AuthGuard>
      ),
      children: [
        {
          index: true,
          element: <h1>Welcome to CoreAdmin</h1>,
        },
        {
          path: 'platform',
          element: <Platform />,
          children: [
            {
              path: 'users',
              element: <PlatformUserList />,
            },
          ],
        },
        {
          path: 'user-course',
          element: <UserCourse />,
        },
        {
          path: 'courses',
          element: <Courses />,
        },
        {
          path: 'extension',
          element: <Extention />,
        },
        {
          path: 'users',
          element: <User />,
        }
      ],
    },
  ],
  {
    future: {
      v7_startTransition: true,
      v7_relativeSplatRoutes: true,
      v7_fetcherPersist: true,
      v7_normalizeFormMethod: true,
      v7_partialHydration: true,
      v7_skipActionErrorRevalidation: true,
    },
  }
);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
