import React from 'react';

const TitleHeader = ({title, description}) => {

  return (
    <>
      <div className="flex justify-between">
        <h1 className="text-xl font-bold">
          {title}
        </h1>
      </div>
      <p className="text-left mt-2 text-sm text-gray-500">
        {description}
      </p>
    </>
  );
};

export default TitleHeader;
