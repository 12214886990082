import React from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "../../slices/authSlice";
import { toast } from 'sonner';

const AuthLoginForm = ({onClose}) => {
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.auth);
  const { register, handleSubmit, formState: { errors } } = useForm();

  const onSubmit = async (data) => {
    dispatch(loginUser(data))
      .unwrap()
      .then((res) => {
        toast.success(res.message);
        if (onClose) onClose();
      })
      .catch((err) => {
        toast.error(err)
      });
  };

  return (
    <div className="container mx-auto">
      <form onSubmit={handleSubmit(onSubmit)} className="max-w-lg mx-auto bg-white rounded-lg">
        <div className="mb-4">
        <label htmlFor="email" className="block text-left text-sm font-medium text-gray-700">Email</label>
          <input
            id="email"
            type="email"
            autoComplete="useremail"
            {...register("email", { required: "Email is required" })}
            className="mt-2 p-2 w-full border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-gray-500"
          />
          {errors.email && (
            <span className="text-red-500 text-sm text-left flex mt-1">{errors.email.message}</span>
          )}
        </div>
        <div className="mb-4">
        <label htmlFor="password" className="block text-left text-sm font-medium text-gray-700">Password</label>
          <input
            id="password"
            type="password"
            autoComplete="current-password"
            {...register("password", { required: "Password is required" })}
            className="mt-2 p-2 w-full border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-gray-500"
          />
          {errors.password && (
            <span className="text-red-500 text-sm text-left flex">{errors.password.message}</span>
          )}
        </div>
        <div className="mb-4 text-center">

            <button
            type="submit"
            className="text-gray-950 hover:text-white border border-gray-700 hover:bg-gray-950 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 dark:border-gray-950 dark:text-gray-950 dark:hover:text-white dark:hover:bg-gray-950"
            disabled={loading}
            >
            {loading ? "Logging in..." : "Submit"}
            </button>
        </div>
      </form>
    </div>
  );
};

export default AuthLoginForm;
