import React from 'react';
import { useSelector } from 'react-redux';
import ModalPopup from './common/ModalPopup';
import AuthLoginForm from './common/AuthLoginForm';

const AuthGuard = ({ children }) => {
  const isAuthenticated = useSelector((state) => !!state.auth.token);
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  React.useEffect(() => {
    if (!isAuthenticated) {
      setIsModalOpen(true);
    }
  }, [isAuthenticated]);

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  if (!isAuthenticated) {
    return (
      <ModalPopup popUpTitle="Login" isOpen={isModalOpen} onClose={handleModalClose}>
        <AuthLoginForm onClose={handleModalClose} />
      </ModalPopup>
    );
  }

  return children;
};

export default AuthGuard;
