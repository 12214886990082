import React, {useState} from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import PlatformList from './PlatformList'; // This will be used only in the /platform route
import TitleHeader from '../common/TitleHeader';

const Platform = () => {
  const location = useLocation();
  const [pageTitle, setPageTitle] = useState('Platforms'); 

  // Check if we're on the "/platform" or "/platform/users" route
  const isUsersPage = location.pathname.includes('users');

  const handleTitleChange = (newTitle) => {
    setPageTitle(newTitle);
  };

  return (
    <>
      <TitleHeader title={isUsersPage ? pageTitle : 'Platforms'} description={isUsersPage ? 'Llist of ' + pageTitle + ' users': 'List of Platforms'}/>

      {/* Only render PlatformList on the /platform route */}
      {!isUsersPage && <PlatformList onTitleChange={handleTitleChange} />}

      {/* The Outlet renders the child components like PlatformUserList when route is /platform/users */}
      <Outlet context={{ onTitleChange: handleTitleChange }} />
    </>
  );
};

export default Platform;
