// RegisterForm.js
import React from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'sonner';

const RegisterForm = ({ onClose, onRefresh }) => {
  // Initialize the useForm hook
  const { register, handleSubmit, formState: { errors }, reset } = useForm();
  const apiUrl = `${process.env.REACT_APP_CORE_API_BASE_URL}`;

  const onSubmit = async (data) => {
    try {
      const token = localStorage.getItem("token");

      const headers = {
        'Content-Type': 'application/json'
      }

      if(token){
        headers['Authorization'] = `Bearer ${token}`
      }

      const response = await fetch(apiUrl + '/api/platform', {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(data)
      });

      const responseData = await response.json();
      if (response.status === 200) {
        toast.success(responseData.message);
        reset()
        if (onClose) {
          console.log('Closing modal');
          onClose() // Close the modal after successful save
        }
        if (onRefresh) {
          console.log('Refreshing parent page');
          onRefresh() // Refresh the parent page after successful save
        }
      } else {
        toast.error(responseData.message);
      }
    } catch (error) {
      toast.error('An error occurred while submitting the form.');
    }
  };

  return (
    <div className="container mx-auto">
      <form onSubmit={handleSubmit(onSubmit)} className="max-w-lg mx-auto bg-white rounded-lg">
        <div className="mb-4">
          <label htmlFor="name" className="block text-left text-sm font-medium text-gray-700">Name</label>
          <input
            id="name"
            {...register('name', {
              required: "Platform name is required",
              minLength: {
                value: 3,
                message: "Platform name must be at least 3 characters long"
              }
            })}
            className="mt-2 p-2 w-full border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-gray-500"
          />
          {errors.name && <span className="text-red-500 text-sm text-left flex mt-1">{errors.name.message}</span>}
        </div>

        <div className="mb-4">
          <label htmlFor="url" className="block text-left text-sm font-medium text-gray-700">Url</label>
          <input
            id="url"
            {...register('url')}
            className="mt-2 p-2 w-full border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-gray-500"
          />
          {errors.url && <span className="text-red-500 text-sm text-left flex">{errors.url.message}</span>}
        </div>

        <div className="mb-4 text-center">
          <button
            type="submit"
            className="text-gray-950 hover:text-white border border-gray-700 hover:bg-gray-950 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 dark:border-gray-950 dark:text-gray-950 dark:hover:text-white dark:hover:bg-gray-950"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default RegisterForm;
