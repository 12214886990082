import React, { useEffect } from "react";
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { fetchUserData } from "../slices/authSlice"
import { UserCircleIcon } from "@heroicons/react/24/outline";


const NavBar = () => {
  const dispatch = useDispatch();
  const { user, token} = useSelector((state) => state.auth);
  const userId = localStorage.getItem("authId");
  useEffect(() => {
    if (userId && token) {
      dispatch(fetchUserData({ userId, token }));
    }
  }, [dispatch, userId, token]);
  
  const userData = user?.data ? user.data[0] : null;


  return (
    <>
      <nav className="bg-white dark:bg-gray-950 fixed w-full z-20 top-0 start-0 border-b border-gray-200 dark:border-gray-600">
        <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto py-4">
          <Link to="/" className="flex items-center space-x-3 rtl:space-x-reverse">
            <span className="self-center text-2xl font-semibold whitespace-nowrap dark:text-white">CoreAdmin</span>
          </Link>

          {userData && (
            <div className="flex items-center md:order-2 space-x-3 md:space-x-0 rtl:space-x-reverse">
              <span className='dark:text-white flex'><UserCircleIcon className="h-6 mr-1" />{userData?.name}</span>
            </div>
          )}
        </div>
      </nav>
    </>
  )
}

export default NavBar
