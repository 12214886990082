// ModalPopup.js
import React from 'react';
import { XCircleIcon } from '@heroicons/react/24/outline';

const ModalPopup = ({popUpTitle, isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white p-6 rounded-lg w-1/3">
        <div className="flex justify-between items-center mb-4">
          <h2 className='text-lg font-bold'>{popUpTitle}</h2>
          <XCircleIcon onClick={onClose} className="w-6 h-6 cursor-pointer" />
        </div>
        {children}
      </div>
    </div>
  );
};

export default ModalPopup;
