
// eslint-disable-next-line
import React, { useState } from "react";
import { Link, useLocation} from 'react-router-dom';
import { ChartPieIcon,
    Squares2X2Icon,
    Square3Stack3DIcon,
    ArrowRightStartOnRectangleIcon,
    ArrowRightEndOnRectangleIcon,
    AcademicCapIcon,
    PuzzlePieceIcon,
    UserGroupIcon
 } from "@heroicons/react/24/outline";
import ModalPopup from "./common/ModalPopup";
import AuthLoginForm from "./common/AuthLoginForm";
import { useSelector, useDispatch } from "react-redux";
import { logout } from '../slices/authSlice';

export function DefaultSidebar() {
    const location = useLocation();
    const isActive = (path) => location.pathname === path ? 'bg-gray-950 dark:bg-gray-700 text-white' : '';

    const [isModalOpen, setIsModalOpen] = useState(false);
    const isAuthenticated = useSelector((state) => !!state.auth.token);
    const dispatch = useDispatch();

    const handleLogin = () => {
        setIsModalOpen(true); // Open the modal
    };

    const handleLogout = () => {
        dispatch(logout());
    };

    const handleModalClose = () => {
        setIsModalOpen(false);
    };


    return (
        <>

            {/* <button data-drawer-target="default-sidebar" data-drawer-toggle="default-sidebar" aria-controls="default-sidebar" type="button" className="inline-flex items-center p-2 mt-2 ms-3 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-950 hover:text-white focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600">
                <span className="sr-only">Open sidebar</span>
                <svg className="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path clipRule="evenodd" fillRule="evenodd" d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"></path>
                </svg>
            </button> */}

            <aside id="default-sidebar" className="fixed left-0 z-10 w-56 h-screen transition-transform -translate-x-full sm:translate-x-0" aria-label="Sidebar">
                <div className="h-full px-3 py-4 overflow-y-auto bg-gray-50 dark:bg-gray-950">
                    <ul className="space-y-2 font-medium">
                        <li>
                            <Link to="/" className={`flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-950 hover:text-white dark:hover:bg-gray-700 group ${isActive('/')}`}>
                                <ChartPieIcon className="h-7" />
                                <span className="ms-3">Dashboard</span>
                            </Link>

                        </li>
                        <li>
                            <Link to="/platform" className={`flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-950 hover:text-white dark:hover:bg-gray-700 group ${isActive('/platform') || isActive('/platform/users')}`}>
                                <Squares2X2Icon className="h-6" />
                                <span className="flex ms-3 whitespace-nowrap">Platform</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/courses" className={`flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-950 hover:text-white dark:hover:bg-gray-700 group ${isActive('/courses')}`}>
                                <Square3Stack3DIcon className="h-6" />
                                <span className="flex ms-3 whitespace-nowrap">Courses</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/user-course" className={`flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-950 hover:text-white dark:hover:bg-gray-700 group ${isActive('/user-course')}`}>
                                <AcademicCapIcon className="h-6" />
                                <span className="flex ms-3 whitespace-nowrap">User Course</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/extension" className={`flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-950 hover:text-white dark:hover:bg-gray-700 group ${isActive('/extension')}`}>
                                <PuzzlePieceIcon className="h-6" />
                                <span className="flex ms-3 whitespace-nowrap">Extension</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/users" className={`flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-950 hover:text-white dark:hover:bg-gray-700 group ${isActive('/users')}`}>
                                <UserGroupIcon className="h-6" />
                                <span className="flex ms-3 whitespace-nowrap">Users</span>
                            </Link>
                        </li>

                        {isAuthenticated ? (
                            <li>
                                <div onClick={handleLogout} className={`flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-950 hover:text-white dark:hover:bg-gray-700 group cursor-pointer`}>
                                    <ArrowRightStartOnRectangleIcon className="h-6" />
                                    <span className="flex ms-3 whitespace-nowrap">Logout</span>
                                </div>
                            </li>
                        ) : (
                            <li>
                                <div onClick={handleLogin} className={`flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-950 hover:text-white dark:hover:bg-gray-700 group cursor-pointer`}>
                                    <ArrowRightEndOnRectangleIcon className="h-6" />
                                    <span className="flex ms-3 whitespace-nowrap">Login</span>
                                </div>
                            </li>
                        )}

                        {/* <li>
                            <Link to="users" className={`flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-950 hover:text-white dark:hover:bg-gray-700 group`}>
                            <svg className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 18">
                                <path d="M14 2a3.963 3.963 0 0 0-1.4.267 6.439 6.439 0 0 1-1.331 6.638A4 4 0 1 0 14 2Zm1 9h-1.264A6.957 6.957 0 0 1 15 15v2a2.97 2.97 0 0 1-.184 1H19a1 1 0 0 0 1-1v-1a5.006 5.006 0 0 0-5-5ZM6.5 9a4.5 4.5 0 1 0 0-9 4.5 4.5 0 0 0 0 9ZM8 10H5a5.006 5.006 0 0 0-5 5v2a1 1 0 0 0 1 1h11a1 1 0 0 0 1-1v-2a5.006 5.006 0 0 0-5-5Z"/>
                            </svg>
                            <span className="flex ms-3 whitespace-nowrap">Users</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="#" className={`flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-950 hover:text-white dark:hover:bg-gray-700 group`}>
                            <svg className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 16">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 8h11m0 0L8 4m4 4-4 4m4-11h3a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-3"/>
                            </svg>
                            <span className="flex ms-3 whitespace-nowrap">Add Auth</span>
                            </Link>
                        </li>
                            */}
                    </ul>
                </div>
            </aside>


            <ModalPopup popUpTitle={'Login'} isOpen={isModalOpen} onClose={handleModalClose}>
                <AuthLoginForm onClose={handleModalClose} />
            </ModalPopup>
        </>
    );
}